<template>
  <div class="products">
    <h1 class="page-title">产品列表</h1>
    
    <!-- 分类筛选 -->
    <div class="filter-section">
      <el-radio-group v-model="selectedCategory" @change="handleCategoryChange">
        <el-radio-button label="">全部</el-radio-button>
        <el-radio-button v-for="category in categories" :key="category" :label="category">
          {{ category }}
        </el-radio-button>
      </el-radio-group>
    </div>
    
    <!-- 产品列表 -->
    <el-row :gutter="20">
      <el-col :xs="12" :sm="8" :md="6" v-for="product in displayProducts" :key="product.id" class="product-col">
        <product-card :product="product" />
      </el-col>
    </el-row>
    
    <!-- 空状态 -->
    <el-empty v-if="displayProducts.length === 0" description="暂无相关产品"></el-empty>
    
    <!-- 分页 -->
    <div class="pagination-container" v-if="displayProducts.length > 0">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="totalProducts"
        :page-size="pageSize"
        :current-page.sync="currentPage"
        @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProductCard from '@/components/product/ProductCard.vue'

export default {
  name: 'Products',
  components: {
    ProductCard
  },
  data() {
    return {
      selectedCategory: '',
      currentPage: 1,
      pageSize: 12,
      categories: ['电子产品', '服装', '家居', '食品']
    }
  },
  computed: {
    ...mapGetters('products', ['allProducts']),
    filteredProducts() {
      if (!this.selectedCategory) {
        return this.allProducts
      }
      return this.allProducts.filter(product => product.category === this.selectedCategory)
    },
    totalProducts() {
      return this.filteredProducts.length
    },
    displayProducts() {
      const start = (this.currentPage - 1) * this.pageSize
      const end = start + this.pageSize
      return this.filteredProducts.slice(start, end)
    }
  },
  created() {
    this.fetchProducts()
  },
  methods: {
    ...mapActions('products', ['fetchProducts', 'fetchProductsByCategory']),
    handleCategoryChange(category) {
      this.currentPage = 1
      if (category) {
        this.fetchProductsByCategory(category)
      } else {
        this.fetchProducts()
      }
    },
    handlePageChange(page) {
      this.currentPage = page
      // 滚动到页面顶部
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style scoped>
.products {
  margin-bottom: 40px;
}

.page-title {
  margin-bottom: 20px;
  color: #333;
}

.filter-section {
  margin-bottom: 30px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.product-col {
  margin-bottom: 20px;
}

.pagination-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
</style> 